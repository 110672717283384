import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDirective } from './drop.directive';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';
import { TemplateRefDirective } from './template-ref.directive';
import { DndDirective } from './dnd.directive';

@NgModule({
  declarations: [DropDirective, TwoDigitDecimalNumberDirective, TemplateRefDirective,DndDirective],
  imports: [
    CommonModule
  ],
  exports: [DropDirective, TwoDigitDecimalNumberDirective, TemplateRefDirective,DndDirective]
})
export class DirectiveModule { }
