<div *ngIf="showHead">
    <nav class="navbar navbar-expand-lg fixed-top p-0 m-0">
        <div class="container-fluid mx-3">
            <div (click)="triggerManageDeductions()">
                <img width="200" class="img-logo" src="assets/icon/DMP-Logo.svg">
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse navbar-right-bg ms-3" id="navbarScroll">
                <div class="row menu-container-upper-join">
                    <div class="col menu-container-upper-join-1"></div>
                    <div class="col menu-container-upper-join-2"></div>
                </div>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav submenu">
                        <section class="nav-link" aria-current="page" *ngIf="userInfo.user_type !== 4"
                            [ngClass]="showDashboardMenu === true ? 'activate':'' " (click)="triggerDashboard()">
                            Dashboard
                        </section>
                        <section *ngIf="userInfo.tenant_type === 'Cash Link'" class="nav-link"
                            [ngClass]="showCashMenu === true ? 'activate':'' " (click)="triggerCashLink()">
                            Cash Link
                        </section>
                        <section class="nav-link" [ngClass]="showManageMenu === true ? 'activate':'' "
                            (click)="triggerManageDeductions()">
                            Manage Deductions
                        </section>

                        <section class="nav-link" *ngIf="userInfo.user_type !== 4"
                            [ngClass]="showReportMenu === true ? 'activate':'' " (click)="triggerReport()">
                            Reporting</section>

                        <section class="nav-link" *ngIf="userInfo.user_type !== 4"
                            [ngClass]="showConnectorMenu === true ? 'activate':'' " (click)="triggerConnector()">
                            Connectors</section>
                    </div>
                </div>
                <ul class="navbar-nav ms-auto my-3 me-4 pe-1 my-lg-0 navbar-nav-scroll">
                    <!-- <div class="icon-background">
                        <img height="18" width="18" src="assets/icon/Notifications.svg">
                    </div> -->
                    <div class="icon-background cursor-pointer" title="Help">
                        <img height="28" width="28" (click) = "Helpmodal($event)" src="assets/icon/Help Icon.svg">
                    </div> 
                    
                    <button *ngIf="this.notificationCount === null || this.notificationCount === 0" class="color-white"
                        mat-icon-button (click)="sidenavService.open(tempRef)"
                        (click)="getNotification()">
                        <mat-icon>notifications</mat-icon>
                    </button>
                    <button *ngIf="this.notificationCount !== null" class="color-white" mat-icon-button
                        (click)="sidenavService.open(tempRef)" (click)="getNotification()">
                        <mat-icon matBadge="{{notificationCount}}" matBadgeColor="warn">notifications</mat-icon>
                    </button>
                    <span class="profile-indicator" [style.background] = "background_color">{{ProfileIcon}}</span>
                    <div class="p-0 m-0 margin-top-name">                      
                        <h5 class="user-name p-0 m-0">{{this.userInfo.first_name}} {{this.userInfo.last_name}}</h5>
                        <h5 class="user-name1 p-0 m-0"> {{this.userInfo.tenant_name}}</h5>
                        <!-- <p class="user-name p-0 m-0">
                            Mike


                        </p> -->
                        <!-- <p class="user-name p-0 m-0">r</p> -->
                        <!-- <img height="12" width="12" src="../../../assets/inmar-icon/ICONS/triangle-down.svg"> -->
                    </div>
                    <div class="icon-background">
                        <div class="d-inline-block" container="body">
                            <img title="Logout" class="cursor-pointer filter-invert"
                                src="../../../assets/inmar-icon/ICONS/log-out.svg" (click)="Logout()">
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    </nav>
    <div *ngIf="helpModal" (click)="$event.stopPropagation()">
        <p class="helpText"><span class="help1">Help<br></span>Please send us an email if you have any question, facing
            difficulties, or want to share your feedback with us. We are happy to support you!<br><a
                href="mailto:deductionshelp@inmar.com" class="help2">deductionshelp@inmar.com</a></p>
    </div>
    <div class="nav-container-1 mt-5  p-0 m-0 fixed-top nav-container-boxshadow" *ngIf="showCashMenu">
        <div class="row p-0 m-0">
            <div class="col-xl-12 mx-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 tab-container ">
                <mat-tab-group (selectedTabChange)="selectCashMenu($event)" [(selectedIndex)]="SelectedCashTab">
                    <mat-tab [label]="tabs.tab" *ngFor="let tabs of cashTabs; let index=index"
                        [routerLink]="tabs.routePath">
                        <ng-template mat-tab-label>
                            {{tabs.tab}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div class="nav-container-1 mt-5  p-0 m-0 fixed-top nav-box-shadow"
        *ngIf="showManageMenu">
        <div class="row p-0 m-0">
            <div class="col-xl-12 mx-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 tab-container ">
                <mat-tab-group (selectedTabChange)="selectMenu($event)" [(selectedIndex)]="SelectedTab">
                    <mat-tab [label]="tabs.tab" *ngFor="let tabs of listOfTabs; let index=index"
                        [routerLink]="tabs.routePath">
                        <ng-template mat-tab-label>
                            {{tabs.tab}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div class="nav-container-1 mt-5  p-0 m-0 fixed-top nav-container-boxshadow"
        *ngIf="showReportMenu">
        <div class="row p-0 m-0">
            <div class="col-xl-12 mx-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 tab-container ">
                <mat-tab-group (selectedTabChange)="selectReportMenu($event)" [(selectedIndex)]="SelectedReportTab"
                    [@.disabled]="true">
                    <mat-tab [label]="tabs.tab" *ngFor="let tabs of reportMenu; let index=index"
                        [routerLink]="tabs.routePath">
                        <ng-template mat-tab-label>
                            {{tabs.tab}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div class="nav-container-1 mt-5  p-0 m-0 fixed-top nav-container-boxshadow"
        *ngIf="showConnectorMenu">
        <div class="row p-0 m-0">
            <div class="col-xl-12 mx-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 tab-container ">
                <mat-tab-group (selectedTabChange)="selectConnectorMenu($event)"
                    [(selectedIndex)]="SelectedConnectorTab" [@.disabled]="true">
                    <mat-tab [label]="tabs.tab" *ngFor="let tabs of connectorMenu; let index=index"
                        [routerLink]="tabs.routePath">
                        <ng-template mat-tab-label>
                            {{tabs.tab}}
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <ng-template #tempRef>
        <div fxLayout="column" fxLayoutGap="10px" class="notification-wrapper">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start  center" fxLayoutGap="10px">
                    <span class="cursor-pointer" (click)="sidenavService.close()" class="material-symbols-outlined">
                        close
                    </span>
                    <span class="header">Notifications</span>
                </div>
                <div>
                    <span class="clrAll" *ngIf="this.keys.length !== 0"
                        (click)="clearAllNotification(0,'clearAll')">clear
                        all</span>
                </div>
            </div>
            <div *ngIf="this.keys.length === 0" class="text-center mt-5">No data found.</div>
            <div *ngFor="let item of keys ">
                <div>
                    <span *ngIf="this.keys.length !== 0" class="dateTimeSts">{{item | replaceunderscore}}</span>
                </div>
                <div *ngFor="let notify of notification[item]">
                    <div class="pb-10">
                        <div class="card">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <img src="assets/icon/message.png" alt="" srcset="">
                                    <span class="sender">{{this.getNameByID(notify.sender)}}</span>

                                </div>
                                <small class="notifyTime">{{getEST(notify?.time) | date:'h:mm a'}}</small>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span class="notifyMsg">{{ shortMsg(notify.message)}} {{notify.message.length
                                    < 80 ? '' : '...' }}</span>
                                        <span class="clrAll" *ngIf="this.keys.length !== 0"
                                            (click)="clearAllNotification(notify?.notification_id,'clear')">clear</span>
                            </div>
                            <div *ngIf="notify.details.level==='invoices'">
                                <span class="invoiceNumber"><a
                                        (click)="openActivitySideNavBar(notify)">{{notify.details.number}}</a></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ng-template>
</div>