import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Directive({
  selector: '[templateRef]'
})

export class TemplateRefDirective implements OnInit, OnDestroy {

  @Input() templateRef;
  private name: string;

  constructor(private registry: UtilService, private template: TemplateRef<any>) { }

  ngOnInit(): void {
    this.name = this.templateRef;
    this.registry.templates[this.name] = this.template;
  }

  ngOnDestroy() {
    delete this.registry.templates[this.name];
  }
}

