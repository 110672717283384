import { Injectable, TemplateRef } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  getRequestUrl(pageNumber?, sort?, searchParams?,totalRecords?) {
    if (sort && sort.direction == '') {
      sort.direction = ''
      sort.active = ''
    }
    let defaultPayload = {
      page: 1,
      size: 50
    }
    if (searchParams && Array.isArray(searchParams) && searchParams.length > 0) {
      let searchUrl = ``;
      searchParams.forEach((param) => {
        if(param.filterCondition === 'DateCondition'){
          const fromDate = param.filterValue.fromDate;
          const toDate = param.filterValue.toDate;
          if(fromDate != '' && (toDate == '' || toDate == undefined)){
            const fromFilterDate = moment(fromDate).format('YYYY-MM-DD');
            searchUrl += `&${param.filterColumn}=gte:${fromFilterDate}`;
          } else if (toDate != '' && (fromDate == '' || fromDate == undefined)) {
            const toFilterDate = moment(toDate).format('YYYY-MM-DD');
            searchUrl += `&${param.filterColumn}=lte:${toFilterDate}`;
          } else if ((fromDate != '' || fromDate != undefined) && (toDate != '' || toDate != undefined)) {
            const fromFilterDate = moment(fromDate).format('YYYY-MM-DD');
            const toFilterDate = moment(toDate).format('YYYY-MM-DD');
            searchUrl += `&${param.filterColumn}=gte:${fromFilterDate}&${param.filterColumn}=lte:${toFilterDate}`;
          }
        }else if(param.filterCondition === 'is-null' || param.filterCondition === 'is-not-null'){
          const condition = this.getConditions(param.filterCondition);
          searchUrl += `&${param.filterColumn}=${condition}`;
        }else if(param.filterCondition == 'file-upload'){
          if(param.filterValue && param.filterValue.noFile1){
            searchUrl += `&${param.filterColumn}=null`;
          }else{
            searchUrl += `&${param.filterColumn}=nnull`;
          }
        }else{
          const condition = this.getConditions(param.filterCondition);
          searchUrl += `&${param.filterColumn}=${condition}:${param.filterValue}`;
        }
      })
      if (searchUrl.startsWith('&')) {
        searchUrl = searchUrl.substring(1);
      }
      if (sort.direction != '' && sort.active != '' && sort.active != null) {
        searchUrl += `&sort=${sort.direction}:${sort.active}`;
      }
      const encodedUrl = encodeURIComponent(searchUrl.trim());
      const requestURl = (typeof pageNumber === 'number') ? `?page=${totalRecords ? 1 : pageNumber + 1}&size=${totalRecords ? totalRecords : defaultPayload.size}&qs=${encodedUrl}` : `?page=${defaultPayload.page}&size=${defaultPayload.size}&qs=${encodedUrl}`;
      return requestURl;
    }
    if (typeof pageNumber === 'number' && sort.active === '') {
      const requestURl = `?page=${pageNumber + 1}&size=${defaultPayload.size}`;
      return requestURl;
    } else if (sort && sort.direction != '' && sort.active != '' && sort.active != null) {
      const encodedSortUrl = encodeURIComponent(`sort=${sort.direction}:${sort.active}`);
      // const encodedSortUrl = encodeURIComponent(`${sort.active}:sort=${sort.direction}`)
      const requestURl = pageNumber ? `?page=${pageNumber + 1}&size=${defaultPayload.size}&qs=${encodedSortUrl}` : `?page=${defaultPayload.page}&size=${defaultPayload.size}&qs=${encodedSortUrl}`;
      return requestURl;
    } else {
      const requestURl = `?page=${defaultPayload.page}&size=${defaultPayload.size}`;
      return requestURl;
    }
  }

  getConditions(condition): string {
    switch (condition) {
      case 'is-equal-to':
        return 'eq';
        break;
      case 'is-not-equal-to':
        return 'ne';
        break;
      case 'contains':
        return 'cts';
        break;
      case 'does-not-contain':
        return 'dnc';
        break;
      case 'starts-with':
        return 'sw';
        break;
      case 'ends-with':
        return 'ew';
        break;
      case 'is-greater-than':
        return 'gt';
        break;
      case 'is-greater-than-or-equal-to':
        return 'gte';
        break;
      case 'is-less-than':
        return 'lt';
        break;
      case 'is-less-than-or-equal-to':
        return 'lte';
        break;
      case 'is-null':
        return 'null';
        break;
      case 'is-not-null':
        return 'nnull';
        break;
        case 'between':
        return 'btw';
        break;
    }
  }
  templates: { [name: string]: TemplateRef<any> } = Object.create(null);
}
