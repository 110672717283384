import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})

export class RestApiService {
  headerToken: any = "";
  public api_path;
  constructor(private httpClient: HttpClient) {
    if ("api_token" in sessionStorage) {
      this.headerToken = sessionStorage.getItem("api_token");
    }
  }

  post_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.post(path, body, {
      headers: headers,
    });
  }

  post_api_call(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.post(path, body, {
        headers: headers,
        responseType: 'blob'
    });
  }

  get_API_New(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.get(path, {
      headers: headers, 
    });
  }

  get_API_export(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.get(path, {
      headers: headers, responseType:'arraybuffer'
    });
  }

  patch_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.patch(path, body, {
      headers: headers,
    });
  }

  fileDownLoad_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;

    return this.httpClient.post(path, body, {
      responseType: "blob",
      headers: headers,
    });
  }


  fileDownLoad_Api_GZip(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;
    return this.httpClient.post(path, body, {
      headers: headers, responseType:'arraybuffer'
    });
  }

  fileUpload_Api(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;

    return this.httpClient.put(path, body, {
      headers: headers,
    });
  }
  
  fileUpload_Api_post(URL, body) {
    let token = sessionStorage.getItem("api_token")
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let path = JSON.parse(localStorage.getItem('baseUrl')).api.url + URL;

    return this.httpClient.post(path, body, {
      headers: headers,
    });
  }
}
