import { Injectable } from "@angular/core";
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Message {
    source: string;
    content: string;
}

@Injectable()
export class WebsocketService {
    private subject: AnonymousSubject<MessageEvent>;
    public messages: Subject<Message>;
    constructor() {
        let token = sessionStorage.getItem("api_token")
        let baseURL = JSON.parse(localStorage.getItem('baseUrl')).api.url
        let socketURL = baseURL.replace(/\/\/|.+\/\//, 'wss://')
        const data_URL = socketURL + "Generic/notificationCount?token=" + token;
        this.messages = <Subject<Message>>this.connect(data_URL).pipe(
            map(
                (response: MessageEvent): Message => {
                    return response.data;
                }
            )
        );
    }

    public connect(url): AnonymousSubject<MessageEvent> {
        if (!this.subject) {
            this.subject = this.create(url);
        }
        else {
            this.subject.unsubscribe();
            this.subject = this.create(url);
        }
        return this.subject;
    }

    public create(url): AnonymousSubject<MessageEvent> {
        let ws = new WebSocket(url);
        let observable = new Observable((obs: Observer<MessageEvent>) => {
            ws.onmessage = obs.next.bind(obs);
            ws.onerror = obs.error.bind(obs);
            // ws.onclose = obs.complete.bind(obs);
            ws.onclose = function (e) {
                setTimeout(function () {
                    let token = sessionStorage.getItem("api_token")
                    let baseURL = JSON.parse(localStorage.getItem('baseUrl')).api.url
                    let socketURL = baseURL.replace(/\/\/|.+\/\//, 'wss://')
                    const data_URL = socketURL + "Generic/notificationCount?token=" + token;
                    const openSocket = (wsURL, waitTimer, waitSeed, multiplier) => {
                        let ws = new WebSocket(wsURL);
                        ws.onopen = () => {
                            waitTimer = waitSeed; //reset the waitTimer if the connection is made
                            ws.onclose = () => {
                                openSocket(ws.url, waitTimer, waitSeed, multiplier);
                            };
                            ws.onmessage = obs.next.bind(obs);
                        };

                        ws.onerror = (e) => {
                            //increase the wait timer if not connected, but stop at a max of 2n-1 the check time
                            //   if(waitTimer < 60000) waitTimer = waitTimer * multiplier; 
                            //   setTimeout(()=>{openSocket(ws.url, waitTimer, waitSeed, multiplier)}, waitTimer);
                        }
                    }
                    openSocket(data_URL, 5000, 5000, 2)
                }, 2000);
            };
            return ws.close.bind(ws);
        });
        let observer = {
            error: null,
            complete: null,
            next: (data: Object) => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify(data));
                }
            }
        };
        return new AnonymousSubject<MessageEvent>(observer, observable);
    }
}