import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./shared-components/login/login.component";
import { AuthGuard } from "./auth-guard/services/auth.guard";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "manage-deductions/new-remitance",
    // canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/main-dashboard/main-dashboard.module").then(
        (m) => m.MainDashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "manage-deductions",
    loadChildren: () =>
      import("./modules/manage-deduction/manage-deduction.module").then(
        (m) => m.ManageDeductionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "cash-link",
    loadChildren: () =>
      import("./modules/manage-deduction/manage-deduction.module").then(
        (m) => m.ManageDeductionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "report",
    loadChildren: () =>
      import("./modules/reporting/reporting/reporting.module").then(
        (m) => m.ReportingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "connector",
    loadChildren: () =>
      import("./modules/connector/connector.module").then(
        (m) => m.ConnectorModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
