import { Host, Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { CommonService } from '../../services/common/common.service';

@Pipe({
    name: 'templateRef'
})
export class TemplateRefPipe implements PipeTransform {

    constructor(private registry: UtilService) {
    }

    transform(name: string): TemplateRef<any> | undefined {
        return this.registry.templates[name];
    }

}