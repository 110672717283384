import { PlatformLocation } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

/**
 * @description
 * @class
 */
@Injectable()
export class AppInitService {
  public Envronment$: Observable<any>;

  constructor(
    public platformLocation: PlatformLocation,
    private http: HttpClient) {
    let queryString = (this.platformLocation as any).location.search
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    if (token !== null) {
      sessionStorage.removeItem("api_token");
      sessionStorage.setItem("api_token", token);
    }
  }

  public loadEnvronments(): any {
    let currentURL = (this.platformLocation as any).location.origin;
    let subDomain;
    let env;
    if (currentURL === 'https://dev-deductionslink.inmar.com') {
      subDomain = 'uat';
      env = 'dev';
    } else if (currentURL === 'https://fintech-ui-dmp-dev1-bryyvagkmq-uc.a.run.app') {
      subDomain = 'uat';
      env = 'dev1';
    } else if (currentURL === 'https://fintech-b2b-dmp-dev2-bryyvagkmq-uc.a.run.app') {
      subDomain = 'uat';
      env = 'dev2';
    } else if (currentURL === 'https://beta-deductionslink.inmar.com' || currentURL === 'https://dl-qa-ui-frontend-service-bryyvagkmq-ue.a.run.app' || currentURL === 'https://fintech-dmp-qa-ui-bryyvagkmq-uc.a.run.app' || currentURL === 'http://localhost:4200') {
      subDomain = 'uat';
      env = 'qa';
    } else if (currentURL === 'https://uat-deductionslink.inmar.com' || currentURL === 'https://fintech-dmp-uat-ui-bryyvagkmq-uc.a.run.app') {
      subDomain = 'uat';
      env = 'uat';
    } else if (currentURL === 'https://deductionslink.inmar.com') {
      subDomain = 'prod';
      env = 'prod';
    } else if (currentURL === 'https://fintech-b2b-dmp-new-bryyvagkmq-uc.a.run.app' || currentURL === 'https://test-api-bryyvagkmq-uc.a.run.app' || currentURL === 'https://dl-test-ui-service-bryyvagkmq-ue.a.run.app') {
      subDomain = 'uat';
      env = 'dev';
    }

    if (!this.Envronment$) {
      let url;
      if (currentURL === 'https://fintech-dmp-qa-ui-bryyvagkmq-uc.a.run.app' || currentURL === 'https://dl-qa-ui-frontend-service-bryyvagkmq-ue.a.run.app' || currentURL ==='https://dl-test-ui-service-bryyvagkmq-ue.a.run.app') {
        url = 'https://us-east1-inm-deductionmgtportal-' + subDomain + '.cloudfunctions.net/dl-qa-ui-secret-function?e=' + env;
      } else {
        url = 'https://us-central1-inm-deductionmgtportal-' + subDomain + '.cloudfunctions.net/fr_config?e=' + env;
      }
      this.Envronment$ = this.http.get(url).pipe(
        shareReplay(1)
      );
    }
    return this.Envronment$;
  }
}